import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-security-admin',
  templateUrl: './security-admin.component.html'
})
export class SecurityAdminComponent implements OnInit, OnDestroy {

  routeParams: Subscription = new Subscription();
  jlocat: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeParams = this.route.paramMap.subscribe(params => {
      this.jlocat = params.get('jlocat');
    });
  }

  ngOnDestroy() {
    this.routeParams.unsubscribe();
  }

}
