import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityAdminComponent } from './security-admin/security-admin.component';


const routes: Routes = [
  { path: 'security-admin/:jlocat' , component: SecurityAdminComponent },
  { path: '**' , redirectTo: 'security-admin/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
